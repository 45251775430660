import { useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, SxProps, Theme } from "@mui/material";
import classes from "./UserRecordDeleteCellItem.module.css";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { FileCommandsIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { multiSitesUserRecordsSlice } from "../../app/store/UserRecordsStore";
import { deleteUserRecord } from "../UserRecordsAPI";
import { selectSiteId } from "../../sites/SiteStore";
import { userRecordsColumnsNames } from "./UserRecordsColumnConfig";
import { RootState } from "../../app/Store";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowBlueButton, narrowOutlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import Dialog from "../../../SolarGikLib/dialogs/Dialog";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { UserAccessType } from "../../user/Models";
export const UserRecordDeleteCellItem: React.FC<GridRenderCellParams> = (params) => {
  const siteId = useSelector(selectSiteId);
  const userType = useSelector((state: RootState) => state.user).userType;
  const userName = useSelector((state: RootState) => state.user).userName;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [popOverOpen, setPopOverOpen] = useState(false);

  const dispatch = useDispatch();
  const recordId: number = params.row[params.field];
  const componentType: string = params.row[userRecordsColumnsNames.Component];
  const reporter: string = params.row[userRecordsColumnsNames.Reporter];

  const DeleteIcon = getIcons(FileCommandsIcons.Delete, IconCategory.FileCommands);
  const DisabledDeleteIcon = getIcons(FileCommandsIcons.DeleteDisabled, IconCategory.FileCommands);

  const isDeleteEnabled =
    /* The below deletion restriction is partial:
  Full restrtiction: user can delete only records that were created by her **via portal**.
  We don't have this records diffrentation yet, rather than component type "System" which is not available in portal-created records.*/
    userType == UserAccessType.Admin || (componentType != "System" && reporter == userName);

  const handleDeleteRecord = async () => {
    try {
      await deleteUserRecord(siteId, recordId);
      dispatch(
        multiSitesUserRecordsSlice.actions.deleteRecord({
          site: siteId,
          recordId: recordId,
        })
      );
    } catch (error) {
      console.log(`Error deleting record :${recordId},error: ${error}`);
    }
  };

  const onDeleteIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopOverOpen(true);
  };

  const deleteButtonStyle: SxProps<Theme> = {
    ...narrowBlueButton,
    margin: "0px 15px",
    "@media screen and (max-width: 1600px)": {
      ...narrowBlueButton["@media screen and (max-width: 1600px)"],
      margin: "0px 10px",
    },
  };

  const cancelButtonStyle: SxProps<Theme> = {
    ...narrowOutlinedWhiteButton,
    margin: "0px 15px",
    "@media screen and (max-width: 1600px)": {
      ...narrowOutlinedWhiteButton["@media screen and (max-width: 1600px)"],
      margin: "0px 10px",
    },
  };

  if (!isDeleteEnabled) {
    return <DisabledDeleteIcon />;
  }
  return (
    <>
      <IconButton onClick={onDeleteIconClick}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        isOpen={popOverOpen}
        anchorEl={anchorEl}
        anchorHorizontalPlacement="center"
        handleClose={() => setPopOverOpen(false)}
        displayHeader={false}
      >
        <div className={classes["dialog"]}>
          <div className={`${TextEnum.h5} ${classes["header"]}`}>
            {"Are you sure you want to delete this record?"}
          </div>
          <div className={classes["dialog-actions"]}>
            <SolarGikButton style={deleteButtonStyle} text="Delete" onClickFunc={handleDeleteRecord} />
            <SolarGikButton
              style={cancelButtonStyle}
              text="Cancel"
              onClickFunc={() => setPopOverOpen(false)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

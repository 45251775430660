import { useDispatch, useSelector } from "react-redux";
import {
  Agriculture,
  Engineering,
  Flood,
  ForkRight,
  PhotoCamera,
  Sensors,
  ToggleOn,
} from "@mui/icons-material";
import TagsHarvesterDeploy from "../features/sites_configuration/TagsHarvester/TagsHarvesterDeploy";
import Card from "../SolarGikLib/cards/Card";
import McsConfiguration from "../features/sites_configuration/mcs/McsConfiguration";
import { AppDispatch, RootState } from "../features/app/Store";
import SafeOverride from "../features/sites_configuration/safeOverride/SafeOverride";
import ModuleMetadataBindingTable from "../features/sites_configuration/moduleMetadata/ModuleMetadataBindingTable";
import SgTabs from "../SolarGikLib/tabs/SgTabs";
import { ITab } from "../SolarGikLib/tabs/TabsModels";
import CamerasConfigTab from "../features/sites_configuration/camerasControl/CamerasConfigTab";
import FeatureFlags from "../features/sites_configuration/featureFlags/FeatureFlagsEditor";
import { setActiveTab } from "../features/app/store/SiteConfigurationPageStore";
import DasConfigTab from "../features/sites_configuration/das/DasConfigTab";
import classes from "./SiteConfiguration.module.css";
import { UserAccessType } from "../features/user/Models";

const tabs: (ITab & { shouldRender?: (state: RootState) => boolean })[] = [
  {
    label: "MCS Config",
    key: "mcsConfig",
    icon: <Engineering />,
    iconPosition: "start",
    content: <McsConfiguration />,
  },
  {
    label: "SiteGateway",
    key: "SiteGateway",
    icon: <ForkRight />,
    iconPosition: "start",
    content: <ModuleMetadataBindingTable />,
    contentLayout: "half-width",
  },
  {
    label: "DAS",
    key: "dasDeploy",
    icon: <Sensors />,
    iconPosition: "start",
    content: (
      <div className={classes.wrapper}>
        <DasConfigTab />
      </div>
    ),
    contentLayout: "max-size",
  },
  {
    label: "TagsHarvester Deploy",
    key: "tagsHarvesterDeploy",
    icon: <Agriculture />,
    iconPosition: "start",
    content: (
      <div className={classes.wrapper}>
        <TagsHarvesterDeploy />
      </div>
    ),
    contentLayout: "half-width",
  },
  {
    label: "Cameras",
    key: "Cameras",
    icon: <PhotoCamera />,
    iconPosition: "start",
    content: (
      <div className={classes.wrapper}>
        <CamerasConfigTab />
      </div>
    ),
    contentLayout: "max-size",
  },
  {
    label: "Override Safe Mode",
    key: "safeModeOverride",
    icon: <Flood />,
    iconPosition: "start",
    content: (
      <Card wrapperStyle={{ width: "100%", height: "500px" }}>
        <SafeOverride />
      </Card>
    ),
    contentLayout: "half-width",
    shouldRender: (state: RootState) =>
      state.user.sudoMode && state.user.userType === UserAccessType.Admin,
  },
  {
    label: "Site Feature Flags",
    key: "featureFlags",
    icon: <ToggleOn />,
    iconPosition: "start",
    content: (
      <Card>
        <FeatureFlags />
      </Card>
    ),
    contentLayout: "half-width",
  },
];

const SiteConfiguration = () => {
  const tabsToRender = tabs.filter(
    (tab) => tab.shouldRender === undefined || useSelector(tab.shouldRender)
  );
  const dispatch = useDispatch<AppDispatch>();
  const activeTab = useSelector((state: RootState) => state.siteConfigurationPage.activeTab);
  return (
    <SgTabs
      tabs={tabsToRender}
      setActiveTab={(tab) => dispatch(setActiveTab(tab))}
      activeTab={activeTab}
    />
  );
};

export default SiteConfiguration;

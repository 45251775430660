import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { IUserDataDto } from "./Models";

export async function getUserData(signal: AbortSignal): Promise<IUserDataDto> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/User/getUserInfo`;
  const httpResponse = await axios.get<IUserDataDto>(url, { signal });
  return httpResponse.data;
}

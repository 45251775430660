export enum UserAccessType {
  None = 0 /*No access*/,
  ViewDashboard = 10 /*Watch only dashboard*/,
  Viewer = 20 /*See all opeartion + maintenenace screens*/,
  Operator = 30 /*Viewer + buttons*/,
  Commissioning = 40 /*Operator + Commissioning screens*/,
  Engineer = 50 /*SuperUser + hidden features / debugging*/,
  Admin = 60 /*Can change metadata of users as wel*/,
}

export enum UserInitializationState {
  NotInitialized = 0,
  Initializing = 1,
  Initialized = 2,
}

export interface IUserData {
  siteIds: string[];
  userType: UserAccessType;
  originalUserType?: UserAccessType;
  userName: string;
  initializationState: UserInitializationState;
  sudoMode: boolean;
}

export interface IUserDataDto {
  userType: UserAccessType;
  siteIds: string[];
}